
.starrating.glyphicon-star {
  margin-right: 2px;
}

.ios, .md, .wp {

  .carttitle {
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    letter-spacing: 0px;
    word-spacing: 0px;
    color: #000000;
    font-weight: 700;
    text-decoration: none solid rgb(68, 68, 68);
    font-style: normal;
    font-variant: normal;
    text-transform: none;
  }

  .cartItemdetail {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0px;
    word-spacing: 0px;
    color: #000000;
    font-weight: 400;
    text-decoration: none solid rgb(68, 68, 68);
    font-style: normal;
    font-variant: normal;
    text-transform: none;
  }

  .cartOptionalItem {
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    letter-spacing: 0px;
    word-spacing: 0px;
    color: #000000;
    font-weight: 700;
    text-decoration: none solid rgb(68, 68, 68);
    font-style: italic;
    font-variant: normal;
    text-transform: none;
  }

  .optionaltitle {
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    letter-spacing: 0px;
    word-spacing: 0px;
    color: #000000;
    font-weight: 700;
    text-decoration: none solid rgb(68, 68, 68);
    font-style: normal;
    font-variant: normal;
    text-transform: none;
  }

  .optionalsubtitle {
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    letter-spacing: 0px;
    word-spacing: 0px;
    color: #000000;
    font-weight: 700;
    text-decoration: none solid rgb(68, 68, 68);
    font-style: normal;
    font-variant: normal;
    text-transform: none;
  }


  .normalTitle {
    //font-size: 1.2em;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 17px;
    letter-spacing: 0px;
    word-spacing: 0px;
    color: #000000;
    font-weight: 700;
    text-decoration: none solid rgb(68, 68, 68);
    font-style: normal;
    font-variant: normal;
    text-transform: none;
    margin: 0px;
  }

  .merchantName {
    //font-size: 1.2em;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    letter-spacing: 0px;
    word-spacing: 0px;
    color: #000000;
    font-weight: 700;
    text-decoration: none solid rgb(68, 68, 68);
    font-style: normal;
    font-variant: normal;
    text-transform: none;
    padding: 1px;
    margin: 0px;
  }

  .merchantTag {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 0px;
    word-spacing: 0px;
    color: #000000;
    font-weight: 400;
    text-decoration: none solid rgb(68, 68, 68);
    font-style: normal;
    font-variant: normal;
    text-transform: none;
    padding: 1px;
    margin: 0px;
  }

  .merchantDetail {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    letter-spacing: 0px;
    word-spacing: 0px;
    color: #000000;
    font-weight: 400;
    text-decoration: none solid rgb(68, 68, 68);
    font-style: normal;
    font-variant: normal;
    text-transform: none;
    padding: 1px;
    margin: 0px;
  }

  .menucategory {
    //font-size: 1.2em;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    letter-spacing: 0px;
    word-spacing: 0px;
    color: #000000;
    font-weight: 700;
    text-decoration: none solid rgb(68, 68, 68);
    font-style: normal;
    font-variant: normal;
    text-transform: none;
  }

  .menuname {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0px;
    word-spacing: 0px;
    color: #191919;
    text-decoration: none solid rgb(68, 68, 68);
    font-style: normal;
    font-variant: normal;
    text-transform: capitalize;
  }

  .menudescription {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0px;
    word-spacing: 0px;
    color: #767676;
    font-weight: 400;
    text-decoration: none solid rgb(68, 68, 68);
    font-style: italic;
    font-variant: normal;
    text-transform: none;
  }

  .menuprice {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0px;
    word-spacing: 0px;
    color: #f53d3d;
    font-weight: 700;
    text-decoration: none solid rgb(68, 68, 68);
    font-style: italic;
    font-variant: normal;
    text-transform: none;
  }

  ion-card.menu_item_card {
    background-color: #FFFBE6;
    border: 0.5px solid;
    margin: 1px;
  }

  .menuitem {
    background: #EDEDED;
  }

  .filter_text {
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 10px;
    letter-spacing: 0px;
    word-spacing: 0px;
    color: #000000;
    font-weight: 700;
    text-decoration: none solid rgb(68, 68, 68);
    font-style: normal;
    font-variant: normal;
    text-transform: none;
    padding: 1px;
    margin: 0px;
  }


  h3 {
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    letter-spacing: 0px;
    word-spacing: 0px;
    color: #000000;
    font-weight: 700;
    text-decoration: none solid rgb(68, 68, 68);
    font-style: normal;
    font-variant: normal;
    text-transform: none;
    padding: 1px;
    margin: 0px;
  }

  h4 {
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    letter-spacing: 0px;
    word-spacing: 0px;
    color: #000000;
    font-weight: 700;
    text-decoration: none solid rgb(68, 68, 68);
    font-style: normal;
    font-variant: normal;
    text-transform: none;
    padding: 1px;
    margin: 0px;
  }

  ion-content {
    background-color: #E8E9E8;
  }

  ion-icon {
    &[class*="my-"] {
      mask-size: contain;
      mask-position: 50% 50%;
      mask-repeat: no-repeat;
      background: currentColor;
      width: 1.0em;
      height: 1.0em;
    }


  }


  @media (max-width: 375px) {
    div.yummy_primary_div {
      margin: auto;
      width: 98%;
    }

    div.loginWindow {
      position: fixed;
      top: 80px;
      left: calc(calc(100% - 330px) / 2);
      height: 600px;
      width: 330px;
    }

    ion-label.tabLabel{
      font-weight: bold;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 12px;
    }

    div.calendarView ::ng-deep p-calendar .ui-datepicker table{
      font-size: 10px;
    }
  }

  @media (min-width: 376px) and (max-width: 450px) {
    div.yummy_primary_div {
      margin: auto;
      width: 96%;
    }

    div.loginWindow {
      position: fixed;
      top: 80px;
      left: calc(calc(100% - 360px) / 2);
      height: 600px;
      width: 360px;
    }

    ion-label.tabLabel{
      font-weight: bold;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 13px;
    }

    div.calendarView ::ng-deep p-calendar .ui-datepicker table{
      font-size: 11px;
    }
  }


  @media (min-width: 451px) and (max-width: 1199px) {
    div.yummy_primary_div {
      margin: auto;
      padding: 3px;
      width: 95%;
    }

    div.loginWindow {
      position: fixed;
      top: 80px;
      left: calc(calc(100% - 420px) / 2);
      height: 600px;
      width: 420px;
    }

    ion-label.tabLabel{
      font-weight: bold;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 14px;
    }

    div.calendarView ::ng-deep p-calendar .ui-datepicker table{
      font-size: 14px;
    }

  }

  @media (min-width: 1200px) {
    div.yummy_primary_div {
      margin: auto;
      padding: 12px;
      width: 1080px;
    }

    div.loginWindow {
      position: fixed;
      top: 80px;
      left: calc(calc(100% - 480px) / 2);
      height: 600px;
      width: 480px;
    }

    ion-label.tabLabel{
      font-weight: bold;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 16px;
    }

    div.calendarView ::ng-deep p-calendar .ui-datepicker table{
      font-size: 16px;
    }
  }


}
